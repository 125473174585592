
import { defineComponent } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import AddSalesmanDrawer from '@/layout/header/partials/course/AddSalesmanDrawer.vue';
// import EditsalesmansInfoDrawer from '@/layout/header/partials/course/EditsalesmansInfoDrawer.vue';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { object } from 'yup/lib/locale';
import { VueCookieNext } from 'vue-cookie-next';

export default defineComponent({
  name: 'salesman-list',
  components: {
    Datatable,
    AddSalesmanDrawer,
  },
  data() {
    return {
      load: false,
      componentKey: 0,
      tableHeader: [
        {
          name: 'Salesman Name',
          key: 'name',
          width: '100px',
          // sortable: true,
        },

        {
          name: 'Address',
          key: 'address',
          width: '100px',
          // sortable: true,
        },

        {
          name: 'Designation',
          key: 'designation',
          width: '100px',
          // sortable: true,
        },
        {
          name: 'Phone',
          key: 'phone',
          width: '100px',
          // sortable: true,
        },
        {
          name: 'Email',
          key: 'email',
          width: '100px',
          // sortable: true,
        },
        {
          name: 'NID',
          key: 'nid',
          width: '100px',
          // sortable: true,
        },
      
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
        },
      ],
      lists: [],
      search: '',
      tableData: [],
      data: {},
    };
  },
  async created() {
    if (VueCookieNext.getCookie('finman_token') != null) {
      await this.getSalesmanData();
      this.emitter.on('salesman-added', async () => {
        await this.getSalesmanData();
      });
    } else {
      this.$router.push('/404');
    }
  },
  methods: {

    async getSalesmanData() {
      await ApiService.get('finmanInvoice/salesman/list')
        .then((response) => {
          this.tableData = response.data.data;
          this.componentKey += 1;
          this.load = true;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    Editsalesmans(data) {
      this.emitter.emit('salesman-edit-type', data);
    },

    Deletesalesman(id) {
      Swal.fire({
        title: 'Are you sure you want to delete this salesman?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('finmanInvoice/salesman/delete/' + `${id}`)
            .then((response) => {
              this.emitter.emit('salesman-added', true);
              Swal.fire('Deleted!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
});
